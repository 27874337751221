import c from "classnames";

import { useAccountDataStore } from "@/store/AccountDataStore";

import Styles from "./loadingContainer.module.scss";

type Props = {
	children: React.ReactNode;
	defaultHeight?: string;
	loading?: boolean;
	light?: boolean;
	overlayLoading?: boolean;
};

export const LoadingContainer = ({
	children,
	defaultHeight,
	loading,
	light = false,
	overlayLoading = false,
}: Props) => {
	const [isLoading] = useAccountDataStore((state) => [state.isLoading]);
	const loadingStatus = loading !== undefined
		? loading
		: isLoading;
	
	return (
		<div
			style={defaultHeight && loadingStatus
				? { height: defaultHeight }
				: {}}
			className={c(Styles.wrapper, {
				[Styles.isLoading]: loadingStatus,
			})}
		>
			<div className={c({ [Styles.loadingOverlay]: overlayLoading })}></div>

			<div
				className={c(Styles.loadingSpinner, { [Styles.light]: light })}
			></div>

			<div className={c(Styles.children)}>{children}</div>
		</div>
	);
};
